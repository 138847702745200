@media (--desktop) {
  .drawer-content {
    width: calc((((100vw - (11 * var(--gutter-24))) / 12) * 3) + (2 * var(--gutter-24)));
  }
}

@media (--desktop-small) {
  .drawer-content {
    width: calc((((100vw - (11 * var(--gutter-24))) / 12) * 4) + (3 * var(--gutter-24)));
  }
}
