.page-view-wrapper .card.small-dropdown,
.small-dropdown {
  padding: var(--size-8);
  box-shadow: 0 2px 8px var(--color-brown-3);
  border: none;

  & .dropdown-item {
    padding: var(--size-12);
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: var(--color-beige);
    }
  }
}

/* Dropdowns with no bg/borders */
.dropdown__container--no-border {
  & .dropdown__input {
    height: var(--guters-16);
  }

  & .dropdown__value-container {
    padding: 0;
    & .dropdown__single-value {
      margin: 0;
    }
  }

  & .dropdown__single-value {
    margin: 0;
    font-family: var(--fonts-family-medium);
  }

  & .dropdown__control {
    background-color: transparent;
    border: none;
    cursor: pointer;
    min-height: 30px;
    height: 30px;
    box-shadow: none;
    flex-direction: row-reverse;

    & .dropdown__value-container {
      padding-left: 0;
    }

    & .dropdown__input-container {
      margin: 0;
    }

    & .css-mohuvp-dummyInput-DummyInput {
      height: 0;
    }

    & .dropdown__indicator {
      color: var(--color-dark-blue);
      padding: 4px;
      height: var(--size-48);
        width: var(--size-48);
    }

    &:hover {
      border-color: transparent;
    }
  }

  & .dropdown__indicator-separator {
    display: none;
  }
}
